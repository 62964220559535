import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"

// Downloads
import FactSheetPatient from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Fact-Sheet-For-Patients.pdf"


const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 40px;
`

const StiDownloads = props => {
	return (
		<DownloadsSection
			id="respi-patient-education"
			viewAllowed={props.viewAllowed}
			data-filter="respiratory" data-filter-type="devices" data-section="patient"
		>
			<StyledContainer>
				<div className="block">
					<div className="block__material">
						<h3>Patient Education</h3>
						<div className="thumbnails_row">							
							<div className="image_column">
								<a href={ FactSheetPatient }  target="_blank" rel="noopener noreferrer" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/respiratory-health-test/fact-sheet-patients.png"
										width={350}
										alt="Fact Sheet for Patients"
										className="thumbnail_image"
									/>
								</a>
								<p><a href={ FactSheetPatient }  target="_blank" rel="noopener noreferrer">Fact Sheet for Patients</a></p>
							</div>
							<div className="image_column">
								<a href="/resources/press-kit/" target="_blank" className="thumbnailLink">
									<StaticImage
										src="../../../assets/images/visby-university/press-kit.png"
										width={350}
										alt="Press Kit"
										className="thumbnail_image"
									/>
								</a>
								<p><a href="/resources/press-kit/" target="_blank" className="thumbnailLink">Press Kit</a></p>
							</div>
						</div>
					</div>
				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default StiDownloads
